<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text"> اضافة امتحان </h1>
        <v-form v-model="isFormValid">
          <!-- exams -->
          <v-row class="mt-5">
            <!-- classSchool -->
            <v-col md="4" sm="6" cols="12">
              <v-autocomplete v-model="data.class_school" :loading="classLoading" :items="classSchoolData"
                :item-text="(item) => item.class_name + ' - ' + item.leader" item-value="_id" clearable outlined dense
                label="الصفوف" multiple @click:clear="data.class_school = null" :rules="Rules.class_school"
                return-object></v-autocomplete>
            </v-col>
            <!-- exams_name -->
            <v-col md="4" sm="6" cols="12">
              <v-text-field v-model="data.exams_name" :rules="Rules.exams_name" dense placeholder="امتحانات الشهر الاول"
                label="وصف الامتحان (شهري, فصلي)" outlined></v-text-field>
            </v-col>
            <!-- exams_schedule -->
            <v-col cols="12">
              <h2 class="mb-2">المواد</h2>
              <div v-for="(examsScheduleItem, index) in data.exams_schedule" :key="index" class="single-product-form">
                <v-card outlined class="d-flex">
                  <v-row>
                    <!-- schedule_exams_subject -->
                    <v-col md="4" sm="6" cols="12">
                      <v-autocomplete v-model="examsScheduleItem.schedule_exams_subject" :items="subjectsData" clearable
                        label="مادة الامتحان" item-text="subject_name" :loading="subjectLoading" outlined dense>
                      </v-autocomplete>
                    </v-col>
                    <!-- exam_date -->
                    <v-col md="4" sm="6" cols="12">
                      <v-menu v-model="examsScheduleItem.menuExamDate" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="289.999998px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="examsScheduleItem.exam_date" dense label="تاريخ الامتحان" outlined
                            clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="examsScheduleItem.exam_date"
                          @input="examsScheduleItem.menuExamDate = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- exams_teacher -->
                    <v-col md="4" sm="6" cols="12">
                      <v-autocomplete v-model="examsScheduleItem.exams_for_teacher" :loading="teacherLoading"
                        :items="teachersData" item-text="account_name" clearable outlined dense label="الاساتذة"
                        @click:clear="examsScheduleItem.exams_for_teacher = null" return-object></v-autocomplete>
                    </v-col>
                    <!-- exam_description -->
                    <v-col cols="12">
                      <v-textarea v-model="examsScheduleItem.exam_description" name="input-7-1"
                        label="ملاحظة عن الامتحان" placeholder="الامتحان من الصفحة 15 الى 200" append-icon="fa-scroll"
                        outlined></v-textarea>
                    </v-col>
                  </v-row>
                  <!-- Item Actions -->
                  <div class="d-flex flex-column item-actions rounded-0 pa-1">
                    <v-btn icon small @click="data.exams_schedule.splice(index, 1)">
                      <v-icon size="20"> {{ icons.mdiClose }} </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </div>
              <div>
                <div class="card_action">
                  <v-btn color="primary" class="mt-4 ml-11" outlined @click="addNewItem"> اضافة مادة امتحانية اخرى
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-col cols="12" class="mt-8">
            <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" :disabled="!isFormValid"
              @click="addForReview"> اضافة للمعاينة <v-icon right> fa-plus </v-icon>
            </v-btn>
          </v-col>
          <table-review :inserted-data="insertedData" @deleteItem="deleteItem" @editItem="editItem"></table-review>
        </v-form>
        <div class="d-flex justify-center mt-2">
          <v-btn :loading="addBtnLoading" :disabled="!isFormValid" color="primary" @click="submitAdd()"> اضافة </v-btn>
          <v-btn class="mr-5" @click="cancelAdd()"> الغاء </v-btn>
        </div>
      </v-card>
    </v-container>
    <!-- Edit dialog -->
    <v-dialog v-model="editDialog.open" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="editDialog.isFormValid">
              <v-row>
                <!-- classSchool -->
                <v-col md="6" cols="12">
                  <v-autocomplete v-model="editedItem.class_school" :loading="classLoading" :items="classSchoolData"
                    :item-text="item => item.class_name + ' - ' + item.leader" item-value="_id" clearable outlined dense
                    label="الصفوف" multiple @click:clear="editedItem.class_school = null" return-object>
                  </v-autocomplete>
                </v-col>
                <!-- exams_name -->
                <v-col md="6" cols="12">
                  <v-text-field v-model="editedItem.exams_name" :rules="Rules.exams_name" dense
                    placeholder="امتحانات الشهر الاول" label="وصف الامتحان (شهري, فصلي)" outlined></v-text-field>
                </v-col>
                <!-- exams_schedule -->
                <v-col cols="12">
                  <h2 class="mb-2">المواد</h2>
                  <div v-for="(examsScheduleItem, index) in editedItem.exams_schedule" :key="index"
                    class="single-product-form">
                    <v-card outlined class="d-flex">
                      <v-row>
                        <!-- schedule_exams_subject -->
                        <v-col md="4" sm="6" cols="12">
                          <v-autocomplete v-model="examsScheduleItem.schedule_exams_subject" :items="subjectsData"
                            clearable label="مادة الامتحان" item-text="subject_name" :loading="subjectLoading" outlined
                            dense>
                          </v-autocomplete>
                        </v-col>
                        <!-- exam_date -->
                        <v-col md="4" sm="6" cols="12">
                          <v-menu v-model="examsScheduleItem.menuExamDateEdit" :close-on-content-click="false"
                            :nudge-right="40" transition="scale-transition" offset-y min-width="289.999998px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="examsScheduleItem.exam_date" dense label="تاريخ الامتحان" outlined
                                clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="examsScheduleItem.exam_date"
                              @input="examsScheduleItem.menuExamDateEdit = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- exams_teacher -->
                        <v-col md="4" sm="6" cols="12">
                          <v-autocomplete v-model="examsScheduleItem.exams_for_teacher" :loading="teacherLoading"
                            :items="teachersData" item-text="account_name" clearable outlined dense label="الاساتذة"
                            @click:clear="examsScheduleItem = null" return-object></v-autocomplete>
                        </v-col>
                        <!-- exam_description -->
                        <v-col cols="12">
                          <v-textarea v-model="examsScheduleItem.exam_description" name="input-7-1"
                            label="ملاحظة عن الامتحان" placeholder="الامتحان من الصفحة 15 الى 200"
                            append-icon="fa-scroll" outlined></v-textarea>
                        </v-col>
                      </v-row>
                      <!-- Item Actions -->
                      <div class="d-flex flex-column item-actions rounded-0 pa-1">
                        <v-btn icon small @click="editedItem.exams_schedule.splice(index, 1)">
                          <v-icon size="20"> {{ icons.mdiClose }} </v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                  <div>
                    <div class="card_action">
                      <v-btn color="primary" class="mt-4 ml-11" outlined @click="addNewItemEdit"> اضافة مادة
                        امتحانيةاخرى </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :disabled="!editDialog.isFormValid" @click="submitEdit"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- Dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api'
import { mdiClose, mdiCogOutline } from '@mdi/js'
import tableReview from "./table/examsTableReview.vue"

export default {
  components: {
    tableReview,
  },


  data() {
    return {

      dialogDelete: false,

      isFormValid: false,

      icons: {
        mdiClose,
        mdiCogOutline,
      },

      editedItem: {},

      editDialog: {
        open: false,
        isFormValid: false,
      },

      Rules: {
        class_school: [value => !!value || 'الصفوف مطلوبة'],
        exams_for_teacher: [value => !!value || 'الاستاذ مطلوب'],
        exams_name: [value => !!value || 'وصف الامتحان مطلوب'],
        schedule_exams_subject: [value => !!value || 'مادة الامتحان مطلوبة'],
        exam_date: [value => !!value || 'تاريخ الامتحان مطلوب'],
      },

      teacherLoading: false,

      teachersData: [],

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      classSchoolData: [],

      subjectsData: [],


      data: {
        class_school: null,
        exams_name: null,
        exams_schedule: [
          {
            exams_for_teacher: null,
            schedule_exams_subject: null,
            exam_date: null,
            exam_description: null,
            menuExamDate: false,
            menuExamDateEdit: false,
          },
        ],
      },


      insertedData: [],

      classLoading: false,

      addBtnLoading: false,

      subjectLoading: false,

      isFormValidEditPassword: false,

      EditPasswordDialog: false,
    }
  },
  created() {
    this.getTeachers()
    this.getClassSchool()
    this.getSubject()
  },

  methods: {
    async getClassSchool() {
      this.classLoading = true

      const response = await Api.getClassSchool()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.classLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.classLoading = false
        this.classSchoolData = response.data.results
      }
    },

    addNewItem() {

      this.data.exams_schedule.push({
        exams_for_teacher: null,
        schedule_exams_subject: null,
        exam_date: null,
        exam_description: null,
        menuExamDate: false,
        menuExamDateEdit: false,
      })


    },

    addNewItemEdit() {
      this.editedItem.exams_schedule.push({
        exams_for_teacher: null,
        schedule_exams_subject: null,
        exam_date: null,
        exam_description: null,
        menuExamDate: false,
        menuExamDateEdit: false,
      })
    },

    async getTeachers() {
      this.teacherLoading = true

      const response = await Api.getAllTeachers()

      if (response.status === 401) {
        this.teacherLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.teacherLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.teacherLoading = false
        this.teachersData = response.data.results
      }
    },

    async getSubject() {
      this.subjectLoading = true

      const response = await Api.getSubjectWithoutFeatures()

      if (response.status === 401) {
        this.subjectLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.subjectLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.subjectLoading = false
        this.subjectsData = response.data.results
      }
    },

    async submitAdd() {
      this.addBtnLoading = true

      const newData = await this.reArrangeInsertedData()

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.addMultiExams(newData, study_year)

      if (response.status === 401) {
        this.addBtnLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addBtnLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addBtnLoading = false
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async reArrangeInsertedData() {
      let newData = [];
      for await (const exam of this.insertedData) {
        newData.push({
          class_school: exam.class_school,
          exams_name: exam.exams_name,
          exams_schedule: await this.reArrangeExamsSchedule(exam.exams_schedule)
        })
      }
      return newData;
    },


    async reArrangeExamsSchedule(exams_schedule) {
      let newData = [];
      for await (const iterator of exams_schedule) {
        newData.push({
          schedule_exams_date: iterator.exam_date,
          schedule_exam_description: iterator.exam_description,
          schedule_exams_subject: iterator.schedule_exams_subject,
          schedule_exams_for_teacher: iterator.exams_for_teacher._id,
          schedule_exams_day: this.getDayName(iterator.exam_date),
        })
      }

      return newData;
    },

    cancelAdd() {
      window.history.back()
    },

    async addForReview() {
      const clone = structuredClone(this.data);

      this.insertedData.push(clone)
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    editItem(item) {
      const exams_schedule_clone = structuredClone(item.exams_schedule);
      this.editedItem = {
        class_school: item.class_school,
        exams_name: item.exams_name,
        exams_schedule: exams_schedule_clone,
      }
      this.editedIndex = this.insertedData.indexOf(item)
      this.editDialog.open = true
    },


    submitEdit() {
      Object.assign(this.insertedData[this.editedIndex], this.editedItem)
      this.editDialog.open = false
    },

    getDayName(date) {
      const dayName = new Date(date).toLocaleString('ar-iq', {
        weekday: 'long',
      })

      return dayName
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.editedIndex = this.insertedData.indexOf(item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.insertedData.splice(this.editedIndex, 1)
      this.dialogDelete = false
    },
  },
}
</script>
<style scoped>
.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}

.card_action {
  display: flex;
  align-content: center;
}
</style>
